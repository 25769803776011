<template>
  <StarRating
    :increment="0.1"
    :rating="rating"
    read-only
    :show-rating="false"
    :star-size="16"
    style="margin-right: 2px"
    active-color="#f7b100"
  />
</template>

<script>
import StarRating from 'vue-star-rating'

export default {
  components: {
    StarRating,
  },
  props: {
    rating: {
      type: Number,
      default: 0,
    },
  },
}
</script>
