import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import {
  ApiResult,
  HotelInformation,
  RiskTypes,
  Trip,
  TripCardsResponse,
  UpdatePaymentMethodPayload,
} from '@/models/dto'

const httpService: HttpService = new HttpService()

export const editTripNotes = async (
  tripId: number,
  payload: Trip
): Promise<AxiosResponse<ApiResult>> =>
  httpService.patch(`https://${baseUrl()}/trips/notes/${tripId}`, payload)

export const tripById = async (tripId: number): Promise<AxiosResponse<Trip>> =>
  httpService.get(`https://${baseUrl()}/trips/${tripId}`)

export const updateDriverHotelInfo = async (
  tripId: number,
  payload: HotelInformation
): Promise<AxiosResponse<ApiResult>> =>
  httpService.patch(`https://${baseUrl()}/trips/${tripId}/updateHotelInfo`, payload)

export const disableRiskTypes = async (
  tripId: number,
  payload: RiskTypes
): Promise<AxiosResponse<ApiResult>> =>
  httpService.post(
    `https://${baseUrl()}/trips/${tripId}/disableRiskTypes`,
    payload
  )

export const siblings = async (
  tripId: number
): Promise<AxiosResponse<TripCardsResponse>> =>
  httpService.get(`https://${baseUrl()}/trips/${tripId}/siblings`)

export const updateBalancePaymentMethod = async (
  tripId: number,
  payload: UpdatePaymentMethodPayload
): Promise<AxiosResponse<ApiResult>> =>
  httpService.patch(
    `https://${baseUrl()}/trips/${tripId}/balancePaymentMethod`,
    payload
  )
