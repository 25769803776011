<template>
  <router-view />
</template>

<script>
export default {
  metaInfo: {
    titleTemplate: '%s - CoachRail',
  },
}
</script>
