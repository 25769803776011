import { DateTime } from 'luxon'

export const mockGarages = {
  count: 5,
  resultList: [
    {
      garageId: 2770,
      garageName: 'ACME',
      address: {
        addressId: 6356,
        addressName: '1160 Hammond Dr, Sandy Springs, GA 30328, USA',
        street1: '1160 Hammond Dr',
        street2: null,
        city: 'Sandy Springs',
        state: 'GA',
        postalCode: '30328',
        lat: 33.9201,
        lng: -84.3486,
        completeAddress: 'Sandy Springs, GA',
      },
      noOfVehicles: 30,
    },
    {
      garageId: 2719,
      garageName: 'ACME - Atlanta',
      address: {
        addressId: 6327,
        addressName: '677 Rockcastle Rd, Cadiz, KY 42211, USA',
        street1: '677 Rockcastle Rd',
        street2: null,
        city: 'Cadiz',
        state: 'KY',
        postalCode: '42211',
        lat: 36.8503,
        lng: -87.85,
        completeAddress: 'Cadiz, KY',
      },
      noOfVehicles: 88,
    },
    {
      garageId: 2723,
      garageName: 'ACME - San Diego',
      address: {
        addressId: 183,
        addressName: '1500 Orange Ave, Coronado, CA 92118, USA',
        street1: '1500 Orange Ave',
        street2: '',
        city: 'Coronado',
        state: 'CA',
        postalCode: '92118',
        lat: 32.6806,
        lng: -117.179,
        completeAddress: 'Coronado, CA',
      },
      noOfVehicles: 21,
    },
    {
      garageId: 2761,
      garageName: 'ACME - Tower',
      address: {
        addressId: 3335,
        addressName: '3340 Peachtree Rd, Atlanta, GA 30305, USA',
        street1: '3340 Peachtree Rd',
        street2: '',
        city: 'Atlanta',
        state: 'GA',
        postalCode: '30305',
        lat: 33.8475,
        lng: -84.3709,
        completeAddress: 'Atlanta, GA',
      },
      noOfVehicles: 9,
    },
    {
      garageId: 2924,
      garageName: 'VA Garage',
      address: {
        addressId: 6387,
        addressName: '315 Elden St, Herndon, VA 20170, USA',
        street1: '315 Elden St',
        street2: null,
        city: 'Herndon',
        state: 'VA',
        postalCode: '20170',
        lat: 38.9662,
        lng: -77.373,
        completeAddress: 'Herndon, VA',
      },
      noOfVehicles: 16,
    },
  ],
}

export const mockRates = {
  count: 2,
  resultList: [
    {
      marketRateId: 433,
      marketId: 1,
      lowRate: 333,
      highRate: 333,
      vehicleType: 'Charter Bus',
      marketRateType: 'Hourly Rate',
      companyId: 2,
    },
    {
      marketRateId: 434,
      marketId: 1,
      lowRate: 355,
      highRate: 777,
      vehicleType: 'Charter Bus',
      marketRateType: 'Daily Rate',
      companyId: 2,
    },
    {
      marketRateId: 433,
      marketId: 1,
      lowRate: 333,
      highRate: 333,
      vehicleType: 'Charter Bus',
      marketRateType: 'Mileage Rate',
      companyId: 2,
    },
  ],
}

export const getDatetimeFromDateAndTimeStrings = function (
  date,
  time,
  timeZone
) {
  if (!date) {
    return null
  }
  const datetime = DateTime.fromJSDate(
    new Date(`${date}T${time || '12:00'}:00`)
  )
    .setZone(timeZone, { keepLocalTime: true })
    .toISO()
  return datetime
}

export const vehicleModelTypes = [
  {
    id: 1,
    label: 'Charter Bus',
    key: 'charter_bus',
    description: null,
  },
  {
    id: 2,
    label: 'Mini Bus',
    key: 'mini_bus',
    description: null,
  },
  {
    id: 3,
    label: 'Sprinter',
    key: 'sprinter',
    description: 'A Sprinter van or comparable',
  },
  {
    id: 4,
    label: 'Party Bus',
    key: 'party_bus',
    description: null,
  },
  {
    id: 5,
    label: 'Sedan',
    key: 'sedan',
    description: null,
  },
  {
    id: 6,
    label: 'SUV',
    key: 'suv',
    description: null,
  },
  {
    id: 7,
    label: 'Limousine',
    key: 'limousine',
    description: null,
  },
  {
    id: 8,
    label: 'Trolley',
    key: 'Trolley',
    description: null,
  },
]
